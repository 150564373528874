import React from 'react';
import { basis, words, solution } from './lib/today';

function App() {
  const [correctGuesses, setCorrectGuesses] = React.useState<string[]>([]);
  const [solved, setSolved] = React.useState(false);

  const highlight = (word: string) => {
    const arr = word.split(basis);
    return arr.map((s, index) => (
      <React.Fragment key={index}>
        {s}
        {index < arr.length - 1 && (
          <span className="text-sky-500">{basis}</span>
        )}
      </React.Fragment>
    ));
  };

  const [message, setMessage] = React.useState('');
  const [value, setValue] = React.useState('');
  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const guess = value.toLowerCase();
    if (guess === solution) {
      setSolved(true);
    } else if (correctGuesses.includes(value)) {
      setMessage('You already got that one');
    } else if (words.includes(guess)) {
      setCorrectGuesses((old) => [...old, guess]);
      setMessage('');
    } else {
      setMessage('Oops, try again');
    }
    setValue('');
  };

  return (
    <div className="min-h-screen font-mono lowercase bg-slate-100 flex flex-col">
      <header>
        <div className="flex">
          <h1 className="text-3xl font-bold underline">Word guessing game</h1>
          <span className="bg-yellow-500 h-fit rounded ml-2 p-0.5 px-1">
            WIP
          </span>
        </div>
      </header>
      <main className="grow">
        {solved ? (
          <p>
            Congratulations! The word was{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-sky-500 via-yellow-500 to-red-500">
              {solution}
            </span>
          </p>
        ) : (
          <>
            <p>
              Find the secret word containing:{' '}
              <span className="text-sky-500">{basis}</span> (
              {correctGuesses.length}/{words.length} guessed)
            </p>
            <form onSubmit={onSubmit}>
              <input
                autoFocus
                autoCapitalize="false"
                className="border"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button className="border ml-2" type="submit">
                guess
              </button>
            </form>
          </>
        )}

        {message && <p className="text-lime-500">{message}</p>}

        {correctGuesses.length > 0 && (
          <>
            <p>Words guessed so far:</p>
            <div className="grid grid-cols-2 tracking-widest">
              <ul>
                {correctGuesses.map((guess, i) => (
                  <li key={i}>{highlight(guess)}</li>
                ))}
              </ul>
            </div>
          </>
        )}
      </main>

      <footer className="bg-slate-600 text-slate-100 py-3 px-4 text-center bottom-0">
        This game is a work in progress – all suggestions are welcome!
      </footer>
    </div>
  );
}

export default App;
