export const ALL_BASES = [
  'harm',
  'pine',
  'word',
  'verb',
  'barn',
  'test',
] as const;

type Basis = typeof ALL_BASES[number];

export const ALL_SOLUTIONS: Record<Basis, string> = {
  harm: 'harmonica',
  pine: 'happiness',
  word: 'afterword',
  verb: 'overblown',
  barn: 'barndoor',
  test: 'testimony',
};

// https://api.datamuse.com/words?sp=*harm*
export const ALL_WORDS: Record<Basis, string[]> = {
  harm: [
    'harmony',
    'charm',
    'pharmaceutical',
    'charming',
    'harm',
    'pharmacy',
    'dharma',
    'harmful',
    'harmonious',
    'pharma',
    'pharmacopoeia',
    'harmonize',
    'harmless',
    'pharmacist',
    'harmonic',
    'pharmacology',
    'philharmonic',
    'charms',
    'charmed',
    'pharm',
    'charmer',
    'harmonica',
    'pharmacokinetics',
    'pharmaceuticals',
    'tharm',
    'disharmony',
    'panpharmacon',
    'pharmacological',
    'harmattan',
    'bpharm',
    'harmonium',
    'harmonia',
    'harmonics',
    'harmonization',
    'biopharmaceutical',
    'pharmacodynamic',
    'enharmonic',
    'unharmed',
    'harmed',
    'pharmaceutics',
    'harmonise',
    'becharm',
    'charmeuse',
    'harms',
    'harmonie',
    'inharmonious',
    'pharmacokinetic',
    'tharms',
    'harmonized',
    'charme',
    'harmonicist',
    'psychopharmacology',
    'charmless',
    'sharman',
    'harmonisation',
    'pharming',
    'pharmacopeia',
    'polypharmacy',
    'sharm',
    'harmsworth',
    'pharmacognosy',
    'harmoniously',
    'pharmacologic',
    'adharma',
    'pharmacologist',
    'harmonist',
    'charmaine',
    'harmlessness',
    'pharmaceutic',
    'harmonical',
    'harmer',
    'harmon',
    'inharmonic',
    'alexipharmic',
    'harmel',
    'harmonizing',
    'anharmonic',
    'pharmacotherapy',
    'harmonite',
    'pharmacies',
    'pharmacognostic',
    'sharma',
    'harmonizer',
    'charmingly',
    'pharmacogenetics',
    'harmonies',
    'disharmonious',
    'pharmacologically',
    'unharmonious',
    'charmful',
    'harmfulness',
    'harmine',
    'harmonically',
    'kharma',
    'pharmacovigilance',
  ],
  pine: [
    'pine',
    'supine',
    'opine',
    'happiness',
    'spine',
    'pineapple',
    'lupine',
    'vulpine',
    'sappiness',
    'alpine',
    'repine',
    'porcupine',
    'spinet',
    'rapine',
    'philippines',
    'opined',
    'atropine',
    'epinephrine',
    'pineal',
    'nappiness',
    'philippine',
    'spineless',
    'spinel',
    'pinecone',
    'piney',
    'orpine',
    'pines',
    'lapine',
    'pinery',
    'pinetum',
    'pineta',
    'snappiness',
    'cisalpine',
    'norepinephrine',
    'piner',
    'transalpine',
    'amlodipine',
    'benzodiazepine',
    'pined',
    'unhappiness',
    'pimpinella',
    'spined',
    'subalpine',
    'vespine',
    'floppiness',
    'spines',
    'pinero',
    'sloppiness',
    'opines',
    'propine',
    'spinelle',
    'reserpine',
    'swampiness',
    'peppiness',
    'tropine',
    'supinely',
    'supineness',
    'chopine',
    'clozapine',
    'sleepiness',
    'alepine',
    'elapine',
    'pinene',
    'repined',
    'creepiness',
    'proserpine',
    'phillipines',
    'pineaster',
    'carbamazepine',
    'pinewood',
    'pineapples',
    'bumpiness',
    'pinealoma',
    'benzodiazepines',
    'skimpiness',
    'mirtazapine',
    'nifedipine',
    'resupine',
    'weepiness',
    'campiness',
    'epinephrin',
    'nimodipine',
    'repiner',
    'quetiapine',
    'ropiness',
    'lumpiness',
    'pinesap',
    'spinescent',
    'dopiness',
  ],
  word: [
    'word',
    'words',
    'sword',
    'crossword',
    'byword',
    'keyword',
    'buzzword',
    'wordsmith',
    'foreword',
    'wordy',
    'wording',
    'watchword',
    'password',
    'catchword',
    'swordplay',
    'broadsword',
    'swordfish',
    'wordplay',
    'afterword',
    'wordsworth',
    'wordnet',
    'wordage',
    'keywords',
    'reword',
    'loanword',
    'wordless',
    'wordrobe',
    'swearword',
    'swordsman',
    'swords',
    'worder',
    'headword',
    'wordbook',
    'wordiness',
    'passwords',
    'swordick',
    'nayword',
    'backsword',
    'codeword',
    'swordtail',
    'afterwords',
    'swordlike',
    'worded',
    'wordsworthian',
    'buzzwords',
    'misword',
    'wordle',
    'wordings',
    'swordsmanship',
    'worden',
    'wordages',
    'wordpress',
    'crosswords',
    'rewording',
    'dword',
    'overword',
    'wordily',
    'miswords',
    'wordlessly',
    'wordmonger',
  ],
  verb: [
    'verbiage',
    'verb',
    'verbose',
    'verbatim',
    'adverb',
    'proverb',
    'proverbial',
    'verbage',
    'verbosity',
    'verbal',
    'reverberate',
    'overbearing',
    'overbid',
    'overboard',
    'overbuy',
    'reverb',
    'verboten',
    'verbena',
    'overbuild',
    'cruciverbalist',
    'overbreed',
    'reverberation',
    'adverbs',
    'overblown',
    'overbold',
    'riverbed',
    'overbear',
    'proverbs',
    'overburden',
    'riverbank',
    'nonverbal',
    'verbalize',
    'verbs',
    'verbally',
    'verba',
    'silverback',
    'overbite',
    'verbile',
    'adverbial',
    'preverbal',
    'riverboat',
    'verbalizing',
    'reverberating',
    'loverboy',
    'overburn',
    'verbalist',
    'overbroad',
    'reverberated',
    'overbalance',
    'overburdened',
    'adverbially',
    'proverbially',
    'transverberate',
    'macroverbumsciolist',
    'overbook',
    'verbigeration',
    'weaverbird',
    'overblow',
    'verbalism',
    'overbarren',
    'verbarian',
    'reverberant',
    'verbicide',
    'overborne',
    'verbify',
    'verbigerate',
    'verbarium',
    'overbrow',
    'verberation',
    'overbilling',
    'overboil',
    'overblew',
    'verbid',
    'verbalization',
    'reverberatory',
    'beaverbrook',
    'overbulk',
    'overbake',
    'verballed',
    'verbals',
    'overbow',
    'overbearingly',
    'verbosely',
    'verbum',
    'reverberations',
    'verbalise',
    'diverb',
    'overbright',
    'verberate',
    'silverberry',
    'silverbush',
  ],
  barn: [
    'barn',
    'barnacle',
    'barney',
    'barnyard',
    'barnet',
    'barnstorm',
    'barny',
    'barnum',
    'barnabas',
    'barnes',
    'barnstormer',
    'barnburner',
    'barnard',
    'barns',
    'barnacles',
    'barnstorming',
    'barnett',
    'barnlike',
    'barnardo',
    'barnaby',
    'barner',
    'barnacled',
    'barnhouse',
    'barnstable',
    'barnstaple',
    'imbarn',
    'cowbarn',
    'barnaul',
    'barndoor',
    'barnaise',
    'barnful',
    'barnhill',
    'barnsley',
    'barna',
    'barnabite',
    'barnegat',
    'barnstormed',
    'barnabus',
    'barnicle',
    'barnstorms',
    'barnstormers',
    'barneveld',
    'barneys',
    'barnwood',
    'barneveldt',
    'barnhart',
    'barnier',
    'barno',
    'barnette',
    'carbarn',
    'barnesville',
    'barnwell',
    'barnets',
    'barnie',
    'barnardine',
    'barnbrack',
  ],
  test: [
    'test',
    'attest',
    'contest',
    'testament',
    'testimony',
    'protest',
    'testing',
    'detest',
    'testify',
    'attestation',
    'testimonial',
    'latest',
    'testudo',
    'testy',
    'intestine',
    'protestant',
    'tester',
    'testis',
    'greatest',
    'testosterone',
    'protester',
    'contested',
    'intestate',
    'testa',
    'testator',
    'neatest',
    'teste',
    'detestable',
    'attested',
    'testicle',
    'incontestable',
    'tests',
    'testes',
    'intestacy',
    'cutest',
    'contestant',
    'tested',
    'gastrointestinal',
    'minutest',
    'protestor',
    'pretest',
    'testate',
    'contestation',
    'intestinal',
    'protesting',
    'faintest',
    'testamur',
    'rightest',
    'attests',
    'remotest',
    'fastest',
    'testamentary',
    'brightest',
    'testee',
    'protestation',
    'attesting',
    'fittest',
    'detestation',
    'protestantism',
    'testable',
    'slightest',
    'retest',
    'intestines',
    'teston',
    'promptest',
    'smartest',
    'testicles',
    'curtest',
    'testified',
    'acutest',
    'testatrix',
    'fleetest',
    'posttest',
    'testily',
    'strictest',
    'contestable',
    'shortest',
    'hottest',
    'justest',
    'softest',
    'tartest',
    'obtest',
    'completest',
    'testern',
    'squattest',
    'bestest',
    'detested',
    'featest',
    'uncontested',
    'sweetest',
    'testoon',
    'fattest',
  ],
};
