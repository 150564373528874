import { ALL_BASES, ALL_WORDS, ALL_SOLUTIONS } from './words';

const getToday = () => {
  const basis = ALL_BASES[Math.floor(Math.random() * ALL_BASES.length)];
  const words = ALL_WORDS[basis];
  const solution = ALL_SOLUTIONS[basis];
  return { basis, words, solution };
};

export const { basis, words, solution } = getToday();
